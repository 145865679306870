import { CommonModule } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator, Validators } from "@angular/forms";
import { mergeMap, Observable } from "rxjs";
import { MaterialModule } from "../../../material.module";
import { Customer } from "../../../models";
import { CustomerService } from "../../../services";

export const CUSTOMER_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomerAutocompleteInputComponent),
    multi: true
};

@Component({
    standalone: true,
    selector: 'customer-autocomplete-input',
    imports: [CommonModule, MaterialModule, ReactiveFormsModule],
    templateUrl: './customer-autocomplete-input.component.html',
    providers: [CUSTOMER_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR]
})
export class CustomerAutocompleteInputComponent implements ControlValueAccessor, Validator, OnInit {

    @Input() label: string;

    @Input() formClass: string;

    @Input() required: boolean | string;

    onChange: any = () => { };
    onTouch: any = () => { };
    onValidatorChange: any = () => { };
    filteredCustomers: Observable<Customer[]>;
    control: FormControl = new FormControl('');
    customersByName: { [customerName: string]: Customer } = {};
    val: string;

    constructor(private customerService: CustomerService) { }

    ngOnInit(): void {
        this.filteredCustomers = this.control.valueChanges.pipe(
            mergeMap(nameInput => (nameInput ? this.customerService.getPagedList(0, 50, [{ active: 'name', direction: 'asc' }], this.getExtraParams(nameInput)) : Promise.resolve({ content: [] }))
                .then(page => {
                    this.customersByName = {};
                    page.content.forEach(c => this.customersByName[c.name] = c);
                    this.val = this.customersByName[nameInput]?.id;
                    this.onChange(this.val);
                    return page.content;
                }))
        );
    }

    private getExtraParams(nameInput: string): HttpParams {
        return new HttpParams().set('searchText', '*' + nameInput + '*');
    }

    set value(val: string) {
        this.val = val;
        this.fillWithCustomerName(val);
        this.onChange(val)
        this.onTouch(val)
    }

    writeValue(val: string): void {
        this.val = val;
        this.fillWithCustomerName(val);
    }

    private fillWithCustomerName(customerId: string): void {
        if (customerId) {
            this.customerService.getById(customerId).then(customer => {
                this.customersByName = { [customer.name]: customer };
                this.control.setValue(customer.name)
            });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        return (control.hasValidator(Validators.required) && !this.val) ? { invalid: true } : null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }
}